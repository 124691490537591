mat-checkbox.checkbox-active-color {
  .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
    background: color(active);
    border-color: color(active);
  }

  .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
    border-color: color(color-d0);
  }

  .mdc-checkbox:hover .mdc-checkbox__ripple {
    opacity: .1!important;
    background-color: color(active)!important;
  }

  .mdc-checkbox .mdc-checkbox__native-control:focus~.mdc-checkbox__ripple {
    opacity: 0!important;
  }
}
