.grey {
  color: color(color-90);

  &-md {
    color: color(color-90);

    @include media('md') {
      color: color(dark-blue);
    }
  }
}

.grey-light {
  color: color(color-61);
}

.highlighted {
  color: color(active);
}

.mono-grey {
  color: color(mono-grey) !important;
}

.mono-dark {
  color: color(mono-dark);
}

.bg-mono-bg {
  background: color(mono-bg);
}

.bg-mono-grey {
  background: color(mono-grey);
}

.green-status {
  color: color(green-status);
}

.main-darkred {
  color: color(main-darkred);
}
