@import "libs/shared/styles/utiles";

.confirm-dialog {
  &__backdrop.cdk-overlay-backdrop,
  &__backdrop-dark.cdk-overlay-backdrop {
    backdrop-filter: blur(6px);
  }

  &__backdrop.cdk-overlay-backdrop {
    background-color: #ffffffcc !important;
  }

  &__backdrop-dark.cdk-overlay-backdrop {
    background-color: rgba(9, 8, 4, 80%) !important;
  }

  &__panel--danger,
  &__panel--default {
    background-color: color(white);
    border-radius: 0.875rem;

    .mat-mdc-dialog-container .mdc-dialog__surface {
      background-color: transparent;
    }

    lv-button .confirm-button {
      padding: rem(9) rem(28);
    }
  }

  &__panel--danger {
    border: 2px solid color(main-darkred);
    box-shadow: 0 10px 40px rgba(212, 31, 33, 0.18);
  }

  &__panel--default {
    border: 1px solid color(main-blue);
    box-shadow: 0 10px 40px rgba(0, 120, 164, 0.12);
  }

  @include media('web-mini') {
    &__backdrop {
      &-with-paddings {
        opacity: 0 !important;
      }

      &-with-paddings + .cdk-global-overlay-wrapper{
        top: 56px !important;
        left: 18px !important;
        width: calc(100% - 36px) !important;
        height: calc(100% - 56px - 48px) !important;
        background-color: rgb(255 255 255 / 80%) !important;
        backdrop-filter: blur(6px);
      }
    }

    &__panel-with-margins {
      margin: 0 rem(10);
    }
  }
}

.testing-element-dialog {
  &__backdrop.cdk-overlay-backdrop{
    backdrop-filter: blur(6px);
  }

  &__backdrop.cdk-overlay-backdrop {
    background-color: #ffffffcc !important;
  }

  &__panel {
    background-color: transparent;
    padding: rem(20);

    .mat-mdc-dialog-container .mdc-dialog__surface {
      background-color: transparent;
      box-shadow: none;
      overflow: visible;
    }
  }
}

.transparent-bg {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    background-color: transparent;
    box-shadow: none;
    overflow: visible;
  }
}

.blurred-background {
  &.cdk-overlay-backdrop{
    backdrop-filter: blur(6px);
  }

  &.cdk-overlay-backdrop {
    background-color: rgba(9, 8, 4, 0.80) !important;
  }
}
