ls-web-auth,
ls-m-auth {
    lv-radio-list {
        mat-radio-group {
            width: 100%;
        }
        
        .ls-radio-container mat-radio-button {
            min-width: unset;
        }
    }
}