mat-radio-group {
  &.negative-margin {
    margin-left: rem(-10);
  }

  &.fz-20 {
    .mdc-label {
      font-size: rem(20);
    }
  }

  &.color-secondary {
    .mdc-label {
      color: $secondary-color;
    }
  }
}
