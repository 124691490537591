.ng-select {
  border-width: 2px;
  border-color: color(color-d0);

  @include media('md') {
    border-width: 1px;
  }
}

.ng-select.ng-select-opened > .ng-select-container {
  border-color: color(color-d0);
}

.ng-select .ng-select-container .ng-value-container .ng-input > input,
.ng-select.ng-select-single .ng-select-container .ng-value-container,
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  font-size: rem(20);
  color: color(color-61);
}

.ng-select.ng-select-opened.ng-select-bottom > .ng-select-container,
.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  border-width: 2px;
  border-color: color(primary);

  @include media('md') {
    border-width: 1px;
  }
}

.ng-dropdown-panel.ng-select-bottom {
  margin-top: 0;
  border-width: 2px;
  border-color: color(color-d0);

  @include media('md') {
    border-width: 1px;
  }
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: color(color-61) !important;
  font-size: rem(20) !important;
}

.ng-select .ng-select-container {
  border-width: 2px;
  border-color: color(color-d0);

  @include media('md') {
    border-width: 1px;
  }
}

.ng-select.ng-select-disabled > .ng-select-container {
  border-color: color(gray-disabled);

  .ng-value-container {
    .ng-placeholder,
    .ng-input > input {
      color: color(gray-disabled);
    }
  }
}

.ng-select > .ng-select-container .ng-arrow-wrapper {
  height: 25px;
}

.ng-select > .ng-select-container .ng-arrow-wrapper .ng-arrow {
  background-image: url('/assets/icons/chevron-bottom.svg');
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;

  padding: 0;
  border: none;
  top: 0;
  width: 100%;
  height: 100%;
}

.ng-select.ng-select-opened > .ng-select-container .ng-arrow {
  top: 0;
  background-image: url('/assets/icons/chevron-top-primary.svg');

}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  font-size: rem(20);
  color: color(color-61);
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  color: color(primary);
  background: rgba(0, 169, 162, 0.1);
  font-weight: normal;
  font-size: rem(20);
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label {
  font-weight: normal;
}

.ng-select.ng-select-disabled > .ng-select-container {
  background-color: color(white);
  cursor: not-allowed;
  opacity: .3;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option,
.ng-select .ng-select-container .ng-value-container .ng-input > input,
.ng-select.ng-select-single .ng-select-container .ng-value-container,
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  font-size: rem(16);
}

.ng-select.ng-invalid > .ng-select-container {
  border-color: color(alert);
}
