@mixin media($mq) {
  $breakpoint-found: false;
  @each $breakpoint in $breakpoints {
    $alias: nth($breakpoint, 1);
    $condition: nth($breakpoint, 2);
    @if $mq == $alias and $condition {
      $breakpoint-found: true;

      @media #{$condition} {
        @content;
      }
    }
  }

  @if $breakpoint-found == false {
    @warn "Oops! Breakpoint ‘#{$mq}’ does not exist."
  }
}
