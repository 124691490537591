svg {
  path {
    transition: fill .3s;
  }
}

.full-height {
  svg {
    width: 100%;
    height: 100%;
  }
}

.users-table__controls {
  svg {
    &:hover {
      path {
        fill: $secondary-color;
      }
    }
  }
}

.users-buttons__mobile {
  svg {
    path {
      fill: $secondary-color;
    }
  }
}

.rtl {
  .mirror-rtl {
    svg {
      transform: scaleX(-1);
    }
  }
}
