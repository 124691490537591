.ls-search {
  &-input {
    &--dark {
      input {
        margin-left: 5px;
        background: transparent !important;
        color: color(white) !important;

        &::placeholder {
          color: color(color-d0) !important;
        }

        &:hover {
          border-color: color(color-61) !important;

          &::placeholder {
            color: color(white) !important;
          }
        }
      }
    }

    &--light {
      input {
        margin-left: 5px;
        background: transparent !important;

        &::placeholder {
          color: color(color-d0);
        }

        &:hover {
          border-color: color(color-61);
        }
      }
    }
  }
}

.rtl .ls-search {
  &-input {
    &--dark,
    &--light {
      input {
        margin-left: 10px;
        margin-right: 5px;
      }
    }
  }
}
