.overrided-mat-radio-button {
  .mdc-radio {
    width: 28px;
    height: 28px;

    &__native-control {
      width: 48px !important;
      height: 48px !important;
    }

    &__background {
      width: 28px;
      height: 28px;

      &::before {
        width: 48px !important;
        height: 48px !important;
      }
    }

    &__outer-circle {
      border-width: 3px;
    }

    &__inner-circle {
      border-width: 14px;
      background-color: color(color-61);
    }

    // Mobile
    @include media('md') {
      width: 22px;
      height: 22px;

      &__native-control {
        width: 42px !important;
        height: 42px !important;
      }

      &__background {
        width: 22px;
        height: 22px;

        &::before {
          width: 42px !important;
          height: 42px !important;
        }
      }

      &__outer-circle {
        border-width: 2px;
      }

      &__inner-circle {
        width: 20px;
        height: 20px;
        top: 1px;
        left: 1px;
        border-width: 2px;
        background-color: color(color-61);
      }
    }
  }

  .mdc-label {
    font-size: rem(20);
    color: color(color-61);
  }
}

.overrided-color-dark-blue {
  .mdc-label {
    color: color(dark-blue);
  }
}

.overrided-color-color-90 {
  .mdc-label {
    color: color(color-90);
  }
}

.overrided-color-fz-18 {
  .mdc-label {
    font-size: rem(18);
  }
}

.overrided-color-fz-16 {
  .mdc-label {
    font-size: rem(16);
  }
}

.overrided-color-90-fz-16 {
  .mdc-label {
    color: color(color-90);
    font-size: rem(16);
  }
}