.popup-wrapper {
  div.error-field {
    bottom: rem(-3);
    font-size: rem(12);
  }

  .ls-btn {

    @include media('md') {
      min-width: unset !important;
    }
  }
}
