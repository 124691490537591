button {
  text-align: center;
  cursor: pointer;
  font-family: $font;

  &:focus-visible {
    outline: none;
  }
}

button.common-btn {
  display: block;
  width: max-content;
}

button.tertiary-btn {
  color: $tertiary-color;
  background: none;
  font-size: rem(14);
  font-weight: 500;
  padding: rem(10) rem(30);
  border: 2px solid color(color-d0);
  border-radius: 4px;
}

/*btns with icons*/
button {
  &.with-icon {
    &.primary {
      svg {
        path {
          fill: color(white);
        }
      }
    }

    &.secondary {
      svg {
        path {
          fill: color(primary);
        }
      }

      &.selected {
        svg {
          path {
            fill: color(pine-green);
          }
        }
      }

      &.disabled {
        svg {
          path {
            fill: $disabled-color;
          }
        }
      }
    }
  }
}

ls-button, ls-button-with-icon {
  &.disabled {
    pointer-events: none;

    button {
      &.with-icon {
        &.secondary {
          svg {
            path {
              fill: $disabled-color;
            }
          }
        }
      }
    }
  }

  &.w-120 {
    display: block;
    width: rem(120);
  }

  &.w-130 {
    display: block;
    width: rem(130);
  }
}

ls-button.program__footer-button__mobile {
  @include media('md') {
    button {
      height: 100%;
      min-width: unset !important;
    }
  }
}

$heightRem: 36, 40;
@each $rem in $heightRem {
  .h-button-#{$rem} {
    button {
      height: rem($rem) !important;
    }
  }
}


$widthRem: 110, 120, 130;
@each $rem in $widthRem {
  .w-button-#{$rem} {
    button {
      max-width: rem($rem) !important;
      width: rem($rem) !important;
    }
  }
}

.buttons-content {
  display: flex;
  justify-content: flex-end;
  padding: 0 rem(20);

  @include media('md') {
    padding: 0;
    margin-top: rem(20);
    justify-content: center;
  }
}

lv-button.disabled {
  .button {
    background: color(mono-bordergrey);
    border-color: color(mono-bordergrey);
    color: color(mono-grey);
  }
}
