.mat-mdc-slider {
  margin: 0!important;
}

.mdc-slider__thumb-knob {
  background-color: color(white)!important;
  height: 42px !important;
  width: 28px !important;
  border-radius: 100px !important;
  box-sizing: border-box !important;
}

.mdc-slider__track,
.mdc-slider__track--inactive,
.mdc-slider__track--active {
  height: 8px!important;
  opacity: 1!important;
}

.mdc-slider__track--active {
  top: 0!important;
}

.mat-mdc-slider .mdc-slider__track--inactive {
  background-color: color(mono-bordergrey)!important;
}

.blue {
  .mdc-slider__thumb-knob {
    border: 3px solid color(main-blue)!important;
  }

  .mdc-slider__track--active_fill {
    background-color: color(main-blue)!important;
    border-color: color(main-blue)!important;
  }
}

.yellow {
  .mdc-slider__thumb-knob {
    border: 3px solid color(main-brightorange)!important;
  }

  .mdc-slider__track--active_fill {
    background-color: color(main-brightorange)!important;
    border-color: color(main-brightorange)!important;
  }
}

.large-thumb {
  .mdc-slider__thumb-knob {
    height: 42px !important;
    width: 28px !important;
  }
}

mat-slider.transform-10 {
  mat-slider-visual-thumb {
    transform: translateX(10px)!important;
  }
}

mat-slider.transform-190 {
  mat-slider-visual-thumb {
    transform: translateX(190px)!important;
  }
}
