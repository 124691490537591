ls-temperature-curve-table {
  .show-mode {
    @include media("md") {
      .temperature-curve__container {
        height: calc(100vh - 360px);
      }

      @include media("mobile") {
        .temperature-curve__container {
          height: unset;
        }
      }
    }

    ls-input-integer,
    ls-input-decimal,
    ls-toggle {
      pointer-events: none;
    }

    input {
      border: none;
    }
  }

  .temperature-curve {
    &__slides {
      .slide {
        .value {
          .error-field {
            bottom: 0.5rem;
          }
        }
      }
    }

    @include media('md') {
      .table-cell {
        .error-field {
          bottom: rem(-20);
        }
      }
    }
  }
}
