$fontPixels: 8, 10, 11, 12, 14, 16, 18, 20, 21, 22, 24, 26, 28, 30, 32, 34, 36, 40, 44, 45, 50, 56, 60;
@each $pixel in $fontPixels {
  .fz-#{$pixel} {
    font-size: rem($pixel);
  }
}

@include media('desktop') {
  @each $pixel in $fontPixels {
    .fz-#{$pixel}-desktop {
      font-size: rem($pixel);
    }
  }
}

@include media('xl') {
  @each $pixel in $fontPixels {
    .fz-#{$pixel}-xl {
      font-size: rem($pixel);
    }
  }
}

@include media('device') {
  @each $pixel in $fontPixels {
    .fz-#{$pixel}-device {
      font-size: rem($pixel);
    }
  }
}

@include media('lg') {
  @each $pixel in $fontPixels {
    .fz-#{$pixel}-lg {
      font-size: rem($pixel);
    }
  }
}

@include media('laptop') {
  @each $pixel in $fontPixels {
    .fz-#{$pixel}-laptop {
      font-size: rem($pixel);
    }
  }
}

@include media('md') {
  @each $pixel in $fontPixels {
    .fz-#{$pixel}-md {
      font-size: rem($pixel);
    }
  }
}

@include media('mobile') {
  @each $pixel in $fontPixels {
    .fz-#{$pixel}-mobile {
      font-size: rem($pixel);
    }
  }
}

$fontWeights: 300, 400, 500, 600, 700, 800, 900;
@each $weight in $fontWeights {
  .fw-#{$weight} {
    font-weight: $weight;
  }
}

@include media('md') {
  @each $weight in $fontWeights {
    .fw-#{$weight}-md {
      font-weight: $weight;
    }
  }
}

//colors
.color-primary {
  color: $primary-color;
}

.color-white {
  color: color(white);
}

.color-secondary {
  color: $secondary-color;
}

.color-tertiary {
  color: $tertiary-color;
}

.color-green {
  color: color(primary);
}

.color-darkgreen {
  color: color(main-darkgreen);
}

.color-danger {
  color: color(danger);
}

.color-error {
  color: color(error);
}

.color-red {
  color: color(main-red);
}

.color-dark-red {
  color: color(main-darkred);
}

.color-black {
  color: #000;
}

.color-disabled {
  color: $disabled-color;
}

.color-grey {
  color: color(mono-grey);
}

.color-mono-bg {
  color: color(mono-bg);
}

.color-darkred {
  color: color(main-darkred);
}

.color-brightorange {
  color: color(main-brightorange);
}

.color-brightgreen {
  color: color(main-brightgreen);
}

.color-darkblue {
  color: color(main-darkblue);
}

.color-darkgrey {
  color: color(mono-darkgrey);
}

.color-f5 {
  color: color(color-f5);
}

.color-main-brightred {
  color: color(main-brightred);
}

.color-60 {
  color: color(color-60);
}

.color-mono-darkgrey {
  color: color(mono-darkgrey);
}

.text-mono-dark {
  color: color(mono-dark);
}

.text-underline {
  text-decoration: underline;
}

.line-height-118 {
  line-height: 1.18;
}

.line-height-115 {
  line-height: 1.15;
}

.line-height-11 {
  line-height: 1.1;
}

.line-height-12 {
  line-height: 1.2;
}

.line-height-14 {
  line-height: 1.4;
}

.line-height-17 {
  line-height: 1.7;
}

.line-height-1 {
  line-height: 1;
}

.line-height-2 {
  line-height: 2;
}

.line-height-07 {
  line-height: .7;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-uppercase {
  text-transform: uppercase;
}

.word-break-all {
  word-break: break-all;
}

.word-break {
  word-break: break-word;
}

.bottom-to-top {
  writing-mode: tb-rl;
  transform: rotate(-180deg);
}

.rtl {
  .text-left {
    text-align: right;
  }

  .text-right {
    text-align: left;
  }
}

@mixin wrap-text($max-width) {
  max-width: $max-width;
  display: block;
  overflow-wrap: break-word;
}

@mixin ellipsis-text($max-width) {
  max-width: $max-width;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
