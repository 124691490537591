mat-checkbox.checkbox-active-color .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
  background: color(white)!important;
  border-radius: 4px!important;
}

.mdc-checkbox, .mdc-checkbox__background {
  width: 24px!important;
  height: 24px!important;
}

mat-checkbox svg {
  display: none;
}

mat-checkbox.mat-mdc-checkbox-checked .mdc-checkbox__background {
  &::after {
    content: '';
    width: 7px;
    height: 2.5px;
    position: absolute;
    top: 43%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    border-bottom: 2px solid color(main-blue);
    border-left: 2px solid color(main-blue);
  }
}

.mdc-checkbox .mdc-checkbox__native-control[disabled]:checked~.mdc-checkbox__background {
  background-color: color(color-d9)!important;
}

mat-checkbox.mat-mdc-checkbox-checked.mat-mdc-checkbox-disabled .mdc-checkbox__background {
  &::after {
    border-bottom: 2px solid color(main-blue);
    border-left: 2px solid color(main-blue);
    border-radius: 4px!important;
  }
}

.mdc-checkbox .mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
  background-color: color(white)!important;
  border-color: color(mono-bordergrey)!important;
  border-radius: 4px!important;
}

mat-checkbox.checkbox-active-color .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background {
  border-color: color(mono-bordergrey)!important;
  border-radius: 4px!important;
}

.mat-mdc-checkbox-ripple, .mdc-checkbox__ripple {
  display: none;
}

mat-checkbox.checkbox-active-color:hover {
  .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background {
    border-color: color(main-blue)!important;
  }
}

.mdc-checkbox:not(:disabled):active .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
  border-color: color(main-blue)!important;
}
