.overrided-ngx-mat-timepicker {
  .mat-mdc-form-field {
    width: 90px !important;
    max-width: 90px !important;
    height: 80px !important;
    border: 1px solid color(color-d0);
    border-radius: 6px;
    padding: 0.55rem 0;
  }

  .mat-mdc-form-field.ng-invalid {
    border: 2px solid color(alert);
  }

  .mat-mdc-input-element {
    font-size: rem(44) !important;
    color: color(color-90) !important;
  }

  .mdc-text-field {
    overflow: unset;

    &--filled {
      --mdc-filled-text-field-active-indicator-height: 0;
      --mdc-filled-text-field-focus-active-indicator-height: 0;
      --mdc-filled-text-field-container-shape: 0;
    }
  }

  tr > td.ngx-mat-timepicker-spacer {
    font-size: 44px;
    padding: 0 rem(12);
    color: color(color-61);
  }

  tr > td.ngx-mat-timepicker-spacer ~ td.ngx-mat-timepicker-spacer {
    padding: 0 rem(5);
  }

  .mat-focus-indicator {
    font-size: rem(16);
    border-radius: 4px !important;
    background-color: color(pitch-blue);
    color: color(white);
    width: 60px !important;
  }

  .ngx-mat-timepicker-meridian {
    display: none;
  }
}

.program__time-picker {
  table {
    margin: 0 auto;
  }

  form {
    border: 2px solid color(color-d0);
    border-radius: 4px;
  }

  &.focused form {
    border: 2px solid color(primary);
  }

  &.ng-invalid form {
    border: 2px solid color(alert);
  }

  .mat-mdc-form-field-subscript-wrapper,
  .mdc-line-ripple {
    display: none !important;
  }

  .ngx-mat-timepicker-meridian {
    button {
      border: none;
      background: none;
    }
  }
}

// Cooling Program, Sprinklers & Foggers Program
.program__time-picker {
  &.border-none__no-editing-mode {
    form {
      border: none;

      .mdc-text-field--filled.mdc-text-field--disabled .mdc-text-field__input,
      .mat-mdc-outlined-button:disabled,
      .ngx-mat-timepicker-spacer,
      .mdc-button__label {
        color: color(color-61);
      }
    }
  }

  .ngx-mat-timepicker-spacer,
  .mdc-button__label {
    font-weight: normal !important;
  }

  .mdc-text-field--filled.mdc-text-field--disabled .mdc-text-field__input,
  .mat-mdc-outlined-button:disabled {
    font-size: 16px !important;
  }
}

.mdc-button__label {
  color: color(color-61) !important;
}

@include media('device') {
  .ngx-mat-timepicker form .ngx-mat-timepicker-table .ngx-mat-timepicker-tbody tr td.ngx-mat-timepicker-meridian .mdc-button {
    width: 30px;
  }
}

.hidden-meridian {
  .ngx-mat-timepicker-meridian {
    display: none;
  }
}