.cdk-overlay-container, .cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.cdk-overlay-container {
  &:empty {
    display: none;
  }
}

.cdk-overlay-backdrop {
  background: rgba(5, 19, 36, 0.3) !important;
}

.cdk-global-overlay-wrapper {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
}

.cdk-overlay-pane {
  max-width: 100vw !important;
  max-height: 100vh !important;
  overflow: auto !important;

  &.mat-datepicker-popup {
    overflow: hidden !important;
  }
}

.autcomplete-timezone-overlay-pane {
  margin-top: 15px;
  border-radius: 4px 4px 14px 14px;
  margin-left: rem(-49);
  width: 424px !important;

  &.add-controller {
    width: 360px !important;
  }

  @media screen and (max-width: 460px) {
    width: calc(100vw - 36px) !important;
    right: rem(18) !important;
    margin-left: unset;

    &.add-controller {
      width: calc(100vw - 36px) !important;
    }
  }

  .mat-mdc-autocomplete-panel {
    padding: 0 rem(18);
  }

  .mat-mdc-option {
    padding: rem(18) 0;
    border-bottom: 1px solid color(color-c8);
    color: color(mono-dark);
  }
  .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled):not(.mat-mdc-option-multiple) {
    background-color: unset;
  }

  .mat-mdc-option:hover:not(.mdc-list-item--disabled) {
    background-color: unset;
  }
}


@include media('md') {
  .cdk-global-overlay-wrapper {
    top: 0 !important;
    left: 0 !important;
    transform: none;
  }

  .cdk-overlay-pane.popup-full__mobile {
    width: 100vw !important;
    max-width: none !important;
    max-height: none !important;
    height: 100% !important;

    .mat-mdc-dialog-container {
      --mdc-dialog-container-shape: 0 !important;
    }
  }

  .cdk-overlay-pane.popup-regular__mobile {
    width: 80vw;
    height: fit-content;
    max-height: 60vh;

    .mat-mdc-dialog-container {
      --mdc-dialog-container-shape: 0 !important;
    }
  }
}
