@use '@angular/material' as mat;
@import "libs/shared/styles/reset";
@import "libs/shared/styles/utiles";
@import "libs/shared/styles/fonts/fonts";
@import "libs/shared/styles/common";
@import "libs/shared/styles/elements/elements";
@import "libs/shared/styles/animations/animations";
@import "libs/shared/styles/override/override";
@import "libs/shared/styles/material-palette";
@import '~@ng-select/ng-select/themes/default.theme.css';
@import "libs/shared/styles/override/flock";
@import "libs/shared/styles/override/testing-dialog";

@include mat.core();

.disabled {
  pointer-events: none;
}

mat-snack-bar-container.updater {
  .mdc-snackbar__surface {
    background-color: #3c7b97;
    color: white;
    font-size: 1.5em;
    simple-snack-bar {
      .mat-mdc-snack-bar-label {
        font-size: 0.6em;
      }
      .mat-mdc-button-persistent-ripple {
        background-color: darkred;
      }
      .mdc-button__label {
        color: white !important;
      }
    }
  }
}
