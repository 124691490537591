$font: 'Poppins', sans-serif;
$font-alternative: Arial;
$base-font-size: 16;
$base-path-font: "/assets/fonts/";

//main font colors
$primary-color: color(dark-blue);
$secondary-color: color(color-61);
$tertiary-color: color(color-90);
$disabled-color: color(gray-disabled);
$not-active-color: color(color-d0);

//menu2 1281+
$topHeight: rem(70);
//1200-1280
$topHeightDevice: rem(100);
//992-1199
$topHeightLg: rem(66);
//768-991
$topHeightLaptop: rem(60);
//576-767
$topHeightMd: rem(60);
//less than 575
$topHeightMobile: rem(60);

$mobilePaddings: rem(32);
$menuInfoMobile: rem(60);
$minHeightDesktop: rem(920);
$minHeightDevice: rem(800);
$minHeightMd: rem(650);
