.m-auto {
  margin: auto;
}

.mt-auto {
  margin-top: auto;
}

$pixels: 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 22, 24, 25, 26, 27, 28, 30, 32, 33, 35, 36, 40, 45,
  46, 48, 50, 52, 55, 56, 58, 60, 65, 66, 69, 70, 75, 78, 80, 82, 85, 86, 88, 90, 96, 108, 112, 123, 115, 142, 143, 145;

@each $pixel in $pixels {
  .p-#{$pixel} {
    padding: rem($pixel);
  }

  .ph-#{$pixel} {
    padding-left: rem($pixel);
    padding-right: rem($pixel);
  }

  .pv-#{$pixel} {
    padding-top: rem($pixel);
    padding-bottom: rem($pixel);
  }

  .pl-#{$pixel} {
    padding-left: rem($pixel);
  }

  .pr-#{$pixel} {
    padding-right: rem($pixel);
  }

  .pt-#{$pixel} {
    padding-top: rem($pixel);
  }

  .pb-#{$pixel} {
    padding-bottom: rem($pixel);
  }

  .m-#{$pixel} {
    margin: rem($pixel);
  }

  .ml-#{$pixel} {
    margin-left: rem($pixel);
  }

  .ml-auto {
    margin-left: auto;
  }

  .mr-#{$pixel} {
    margin-right: rem($pixel);
  }

  .mh-#{$pixel} {
    margin-left: rem($pixel);
    margin-right: rem($pixel);
  }

  .mt-#{$pixel} {
    margin-top: rem($pixel);
  }

  .mb-#{$pixel} {
    margin-bottom: rem($pixel);
  }

  .gap-#{$pixel} {
    gap: rem($pixel);
  }

  /*mirror paddings/margins for rtl*/
  .rtl {
    .p-#{$pixel} {
      padding: rem($pixel);
    }

    .pl-#{$pixel} {
      padding-left: unset;
      padding-right: rem($pixel);
    }

    .pr-#{$pixel} {
      padding-right: unset;
      padding-left: rem($pixel);
    }

    .ml-#{$pixel} {
      margin-left: unset;
      margin-right: rem($pixel);
    }

    .ml-auto {
      margin-right: auto;
    }

    .mr-#{$pixel} {
      margin-right: unset;
      margin-left: rem($pixel);
    }
  }
}

@include media('desktop') {
  @each $pixel in $pixels {
    .p-#{$pixel}-desktop {
      padding: rem($pixel);
    }

    .ph-#{$pixel}-desktop {
      padding-left: rem($pixel);
      padding-right: rem($pixel);
    }

    .pv-#{$pixel}-desktop {
      padding-top: rem($pixel);
      padding-bottom: rem($pixel);
    }

    .pl-#{$pixel}-desktop {
      padding-left: rem($pixel);
    }

    .pr-#{$pixel}-desktop {
      padding-right: rem($pixel);
    }

    .pt-#{$pixel}-desktop {
      padding-top: rem($pixel);
    }

    .pb-#{$pixel}-desktop {
      padding-bottom: rem($pixel);
    }

    .m-#{$pixel}-desktop {
      margin: rem($pixel);
    }

    .ml-#{$pixel}-desktop {
      margin-left: rem($pixel);
    }

    .mr-#{$pixel}-desktop {
      margin-right: rem($pixel);
    }

    .mt-#{$pixel}-desktop {
      margin-top: rem($pixel);
    }

    .mb-#{$pixel}-desktop {
      margin-bottom: rem($pixel);
    }

    /*mirror paddings/margins for rtl*/
    .rtl {
      .p-#{$pixel}-desktop {
        padding: rem($pixel);
      }

      .pl-#{$pixel}-desktop {
        padding-left: unset;
        padding-right: rem($pixel);
      }

      .pr-#{$pixel}-desktop {
        padding-right: unset;
        padding-left: rem($pixel);
      }

      .ml-#{$pixel}-desktop {
        margin-left: unset;
        margin-right: rem($pixel);
      }

      .mr-#{$pixel}-desktop {
        margin-right: unset;
        margin-left: rem($pixel);
      }
    }
  }
}

@include media('xl') {
  @each $pixel in $pixels {
    .p-#{$pixel}-xl {
      padding: rem($pixel);
    }

    .ph-#{$pixel}-xl {
      padding-left: rem($pixel);
      padding-right: rem($pixel);
    }

    .pv-#{$pixel}-xl {
      padding-top: rem($pixel);
      padding-bottom: rem($pixel);
    }

    .pl-#{$pixel}-xl {
      padding-left: rem($pixel);
    }

    .pr-#{$pixel}-xl {
      padding-right: rem($pixel);
    }

    .pt-#{$pixel}-xl {
      padding-top: rem($pixel);
    }

    .pb-#{$pixel}-xl {
      padding-bottom: rem($pixel);
    }

    .m-#{$pixel}-xl {
      margin: rem($pixel);
    }

    .ml-#{$pixel}-xl {
      margin-left: rem($pixel);
    }

    .mr-#{$pixel}-xl {
      margin-right: rem($pixel);
    }

    .mt-#{$pixel}-xl {
      margin-top: rem($pixel);
    }

    .mb-#{$pixel}-xl {
      margin-bottom: rem($pixel);
    }

    /*mirror paddings/margins for rtl*/
    .rtl {
      .p-#{$pixel}-xl {
        padding: rem($pixel);
      }

      .pl-#{$pixel}-xl {
        padding-left: unset;
        padding-right: rem($pixel);
      }

      .pr-#{$pixel}-xl {
        padding-right: unset;
        padding-left: rem($pixel);
      }

      .ml-#{$pixel}-xl {
        margin-left: unset;
        margin-right: rem($pixel);
      }

      .mr-#{$pixel}-xl {
        margin-right: unset;
        margin-left: rem($pixel);
      }
    }
  }
}

@include media('device') {
  @each $pixel in $pixels {
    .p-#{$pixel}-device {
      padding: rem($pixel);
    }

    .ph-#{$pixel}-device {
      padding-left: rem($pixel);
      padding-right: rem($pixel);
    }

    .pv-#{$pixel}-device {
      padding-top: rem($pixel);
      padding-bottom: rem($pixel);
    }

    .pl-#{$pixel}-device {
      padding-left: rem($pixel);
    }

    .pr-#{$pixel}-device {
      padding-right: rem($pixel);
    }

    .pt-#{$pixel}-device {
      padding-top: rem($pixel);
    }

    .pb-#{$pixel}-device {
      padding-bottom: rem($pixel);
    }

    .m-#{$pixel}-device {
      margin: rem($pixel);
    }

    .ml-#{$pixel}-device {
      margin-left: rem($pixel);
    }

    .mr-#{$pixel}-device {
      margin-right: rem($pixel);
    }

    .mt-#{$pixel}-device {
      margin-top: rem($pixel);
    }

    .mb-#{$pixel}-device {
      margin-bottom: rem($pixel);
    }

    /*mirror paddings/margins for rtl*/
    .rtl {
      .p-#{$pixel}-device {
        padding: rem($pixel);
      }

      .pl-#{$pixel}-device {
        padding-left: unset;
        padding-right: rem($pixel);
      }

      .pr-#{$pixel}-device {
        padding-right: unset;
        padding-left: rem($pixel);
      }

      .ml-#{$pixel}-device {
        margin-left: unset;
        margin-right: rem($pixel);
      }

      .mr-#{$pixel}-device {
        margin-right: unset;
        margin-left: rem($pixel);
      }
    }
  }
}

@include media('lg') {
  @each $pixel in $pixels {
    .p-#{$pixel}-lg {
      padding: rem($pixel);
    }

    .ph-#{$pixel}-lg {
      padding-left: rem($pixel);
      padding-right: rem($pixel);
    }

    .pv-#{$pixel}-lg {
      padding-top: rem($pixel);
      padding-bottom: rem($pixel);
    }

    .pl-#{$pixel}-lg {
      padding-left: rem($pixel);
    }

    .pr-#{$pixel}-lg {
      padding-right: rem($pixel);
    }

    .pt-#{$pixel}-lg {
      padding-top: rem($pixel);
    }

    .pb-#{$pixel}-lg {
      padding-bottom: rem($pixel);
    }

    .m-#{$pixel}-lg {
      margin: rem($pixel);
    }

    .ml-#{$pixel}-lg {
      margin-left: rem($pixel);
    }

    .mr-#{$pixel}-lg {
      margin-right: rem($pixel);
    }

    .mt-#{$pixel}-lg {
      margin-top: rem($pixel);
    }

    .mb-#{$pixel}-lg {
      margin-bottom: rem($pixel);
    }

    /*mirror paddings/margins for rtl*/
    .rtl {
      .p-#{$pixel}-lg {
        padding: rem($pixel);
      }

      .pl-#{$pixel}-lg {
        padding-left: unset;
        padding-right: rem($pixel);
      }

      .pr-#{$pixel}-lg {
        padding-right: unset;
        padding-left: rem($pixel);
      }

      .ml-#{$pixel}-lg {
        margin-left: unset;
        margin-right: rem($pixel);
      }

      .mr-#{$pixel}-lg {
        margin-right: unset;
        margin-left: rem($pixel);
      }
    }
  }
}

@include media('md') {
  @each $pixel in $pixels {
    .p-#{$pixel}-md {
      padding: rem($pixel);
    }

    .ph-#{$pixel}-md {
      padding-left: rem($pixel);
      padding-right: rem($pixel);
    }

    .pv-#{$pixel}-md {
      padding-top: rem($pixel);
      padding-bottom: rem($pixel);
    }

    .pl-#{$pixel}-md {
      padding-left: rem($pixel);
    }

    .pr-#{$pixel}-md {
      padding-right: rem($pixel);
    }

    .pt-#{$pixel}-md {
      padding-top: rem($pixel);
    }

    .pb-#{$pixel}-md {
      padding-bottom: rem($pixel);
    }

    .m-#{$pixel}-md {
      margin: rem($pixel);
    }

    .ml-#{$pixel}-md {
      margin-left: rem($pixel);
    }

    .mr-#{$pixel}-md {
      margin-right: rem($pixel);
    }

    .mt-#{$pixel}-md {
      margin-top: rem($pixel);
    }

    .mb-#{$pixel}-md {
      margin-bottom: rem($pixel);
    }

    .gap-#{$pixel}-md {
      gap: rem($pixel);
    }

    /*mirror paddings/margins for rtl*/
    .rtl {
      .p-#{$pixel}-md {
        padding: rem($pixel);
      }

      .pl-#{$pixel}-md {
        padding-left: unset;
        padding-right: rem($pixel);
      }

      .pr-#{$pixel}-md {
        padding-right: unset;
        padding-left: rem($pixel);
      }

      .ml-#{$pixel}-md {
        margin-left: unset;
        margin-right: rem($pixel);
      }

      .mr-#{$pixel}-md {
        margin-right: unset;
        margin-left: rem($pixel);
      }
    }
  }

  .ml-auto-md {
    margin-left: auto;
  }

  .mr-auto-md {
    margin-right: auto;
  }

  .rtl {
    .ml-auto-md {
      margin-left: unset;
      margin-right: auto;
    }

    .mr-auto-md {
      margin-right: unset;
      margin-left: auto;
    }
  }
}

@include media('mobile') {
  @each $pixel in $pixels {
    .p-#{$pixel}-mobile {
      padding: rem($pixel);
    }

    .ph-#{$pixel}-mobile {
      padding-left: rem($pixel);
      padding-right: rem($pixel);
    }

    .pv-#{$pixel}-mobile {
      padding-top: rem($pixel);
      padding-bottom: rem($pixel);
    }

    .pl-#{$pixel}-mobile {
      padding-left: rem($pixel);
    }

    .pr-#{$pixel}-mobile {
      padding-right: rem($pixel);
    }

    .pt-#{$pixel}-mobile {
      padding-top: rem($pixel);
    }

    .pb-#{$pixel}-mobile {
      padding-bottom: rem($pixel);
    }

    .m-#{$pixel}-mobile {
      margin: rem($pixel);
    }

    .ml-#{$pixel}-mobile {
      margin-left: rem($pixel);
    }

    .mr-#{$pixel}-mobile {
      margin-right: rem($pixel);
    }

    .mt-#{$pixel}-mobile {
      margin-top: rem($pixel);
    }

    .mb-#{$pixel}-mobile {
      margin-bottom: rem($pixel);
    }

    /*mirror paddings/margins for rtl*/
    .rtl {
      .p-#{$pixel}-mobile {
        padding: rem($pixel);
      }

      .pl-#{$pixel}-mobile {
        padding-left: unset;
        padding-right: rem($pixel);
      }

      .pr-#{$pixel}-mobile {
        padding-right: unset;
        padding-left: rem($pixel);
      }

      .ml-#{$pixel}-mobile {
        margin-left: unset;
        margin-right: rem($pixel);
      }

      .mr-#{$pixel}-mobile {
        margin-right: unset;
        margin-left: rem($pixel);
      }
    }
  }
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
