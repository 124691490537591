@import "libs/shared/styles/utiles";

.mat-expansion-panel {
    box-shadow: none !important;
    border-radius: 0 !important;

    .mat-expansion-panel-header {
        padding: rem(20) !important;
        height: rem(85) !important;
        border-bottom: 1px solid color(table-border) !important;
        
        .mat-expansion-indicator::after {
            height: rem(5);
            width: rem(5);
        }
    }
    
    .mat-expansion-panel-content {
        background-color: color(color-f9) !important;

        .mat-expansion-panel-body {
            padding: rem(20) rem(24) !important;
        }
    }
}