@keyframes hideAfterDelay {
  0% {
    display: block;
  }
  99% {
    display: block;
  }
  100% {
    display: none;
  }
}

// Firefox specific selector. Appears that you cannot change display in keyframes on firefox.
@supports (-moz-appearance:none) {
  @keyframes hideAfterDelay {
    0% {
      display: block;
    }
    99% {
      display: block;
    }
    100% {
      display: none;
      opacity: 0;
      z-index: -1000;
    }
  }
}