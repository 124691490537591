.error-field {
  position: absolute;
  color: color(danger);
  font-size: rem(12);
  bottom: rem(-14);
  white-space: pre;
  line-height: 1.2;
}

ls-house-sizes-settings-form {
  .house-roof-height .error-field {
    top: -1.5rem;
    height: rem(20);
  }
}

.add-day .error-field {
  transform: translateX(-33%);
}

@include media('md') {
  .table-cell {
    .error-field {
      bottom: 0;
    }
  }
}
