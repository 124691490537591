ls-flock-house-mode-form,
ls-flock-bird-weight-form {
  .show-mode {

    ls-input-integer,
    ls-input-decimal,
    ls-toggle {
      pointer-events: none;
    }

    input {
      border: none;
    }
  }
}

ls-flock-house-mode-form,
ls-flock-bird-weight-form {
  input {
    max-width: 120px !important;
    width: 120px !important;
  }

  .separate-male-and-female {
    @include media('md') {
      input {
        max-width: 100px !important;
        width: 100px !important;
      }
    }
  }
}

ls-flock-bird-weight-form,
ls-flock-house-mode-form {
  div.error-field {
    font-size: rem(10);
    bottom: rem(-4);
  }
}

ls-flock-settings {
  @include media('md') {
    ls-header-title {
      width: 100%;
    }

    .header {
      &__buttons {
        width: 100%;
      }
    }
  }
}

ls-flock-settings,
ls-flock-bird-weight {
  .page-content {
    @include media('md') {
      background-color: white;
    }
  }
}

ls-flock-wizard {
  .bird-weight {
    &__container {
      @include media('md') {
        height: calc(100vh - 497px) !important;
      }
    }
  }

  .house-mode__container,
  .label__growth-day {
    margin-top: rem(32) !important;
  }

  ls-flock-house-mode-form,
  ls-flock-settings-form {
    .buttons {
      padding-bottom: rem(20);
    }
  }
}
