.popup-message {
  width: rem(350);
  max-width: 95vw;
  padding: rem(20);

  &__icon {
    width: rem(48);
    height: rem(48);
  }
}

.popup {
  padding: rem(20);

  &-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
