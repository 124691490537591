.mat-button-toggle-group {
  border: none !important;
}

.mat-button-toggle {
  background-color: color(white) !important;
  color: color(typography) !important;
  border: 2px solid color(table-border) !important;
}

.mat-button-toggle-checked {
  background-color: color(light-blue) !important;
  color: color(primary) !important;
  border: 2px solid color(primary) !important;

  &.disabled-state {
    background-color: color(gray-disabled) !important;
    border-color: color(color-90) !important;
    color: color(color-90) !important;
  }
}

.mat-button-toggle-label-content {
  line-height: rem(36) !important;
  padding: 0 rem(20) !important;
}
